import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { datadogLogs } from '@datadog/browser-logs';

if (environment.production) {
  datadogLogs.init({
    clientToken: 'pub18eed48ccca1d26afdc34d3f8da15f1d',
    site: 'ddog-gov.com',
    service: 'mtx-general-webui',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    telemetrySampleRate: 100,
    allowUntrustedEvents: true,
    forwardConsoleLogs: ['log', 'warn', 'error', 'info', 'debug'],
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });
