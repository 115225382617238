import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { SharedModule } from '../shared/shared.module';

/**
 * Module for checking credentials and redirecting/setting authentication.
 */
@NgModule({ declarations: [LoginComponent],
  exports: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    LoginRoutingModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())] })
export class LoginModule {}
