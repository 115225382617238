import { AuthService } from 'src/app/auth.service';
import { first } from 'rxjs/operators';
import type { OnInit } from '@angular/core';
import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';
import type { PermissionRule } from '@angeltrax/ngx-aui/lib/shared';
import { from } from 'rxjs';

// Directive decorator
@Directive({ selector: '[mtxIfUserHasPermission]' })
// Directive class
export class IfUserHasPermissionDirective implements OnInit {

  @Input() public mtxIfUserHasPermission?: PermissionRule;

  public constructor(@Inject(ElementRef) private readonly el: ElementRef,
    @Inject(Renderer2) private readonly renderer: Renderer2,
    @Inject(AuthService) private readonly authService: AuthService) {
    renderer.setStyle(el.nativeElement, 'display', 'none');
  }

  public ngOnInit(): void {
    this.reset();
  }

  private reset(): void {
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');

    if (!this.mtxIfUserHasPermission) {
      return;
    }

    if ((this.mtxIfUserHasPermission.oneOf ?? []).length === 0 &&
     (this.mtxIfUserHasPermission.allOf ?? []).length === 0) {
      this.renderer.setStyle(this.el.nativeElement, 'display', '');
      return;
    }

    if (this.mtxIfUserHasPermission.oneOf) {
      from(this.authService
        .checkUserHasAtLeastOnePermission(this.mtxIfUserHasPermission.oneOf))
        .pipe(first())
        .subscribe((x) => {
          if (x) {
            this.renderer.setStyle(this.el.nativeElement, 'display', '');
          }
        });
    }

    if (this.mtxIfUserHasPermission.allOf) {
      from(this.authService
        .checkUserHasAllPermissions(this.mtxIfUserHasPermission.allOf ?? []))
        .pipe(first())
        .subscribe((x) => {
          if (x) {
            this.renderer.setStyle(this.el.nativeElement, 'display', '');
          }
        });
    }
  }

}
