import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import type { AuiRoutes } from '@angeltrax/ngx-aui/lib/shared';
import { AuiUserCanLoadGuard } from '@angeltrax/ngx-aui';
import { ModuleRedirectResolver } from './module-redirect.resolver';

export const routes: AuiRoutes = [
  {
    path: '',
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        resolve: { moduleRedirect: ModuleRedirectResolver },
        component: undefined,
        data: {
          showInNav: false,
        },
        pathMatch: 'full',
        children: [],
      },

      {
        path: 'admin',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./admin/admin.module')).AdminModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['admin_*'] },
          icon: 'fa fa-cogs',
          title: 'shared.admin',
          showInNav: true,
          priority: 7,
        },
      },

      {
        path: 'avl',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./avl/avl.module')).AvlModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['avl_*'] },
          icon: 'fas fa-map-marker-alt',
          title: 'shared.avl',
          showInNav: true,
          priority: 1,
        },
      },

      {
        path: 'driver-behavior',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./driver-behavior/driver-behavior.module')).DriverBehaviorModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['da_*'] },
          icon: 'fa fa-bar-chart',
          title: 'shared.driver-behavior',
          showInNav: true,
          priority: 2,
        },
      },

      {
        path: 'passenger-count',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./transit/transit.module')).TransitModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['transit_*'] },
          icon: 'fa fa-bus',
          title: 'shared.passenger-count',
          showInNav: true,
          priority: 4,
        },
      },

      {
        path: 'student-tracking',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./student-tracking/student-tracking.module')).StudentTrackingModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['st_*'] },
          icon: 'fas fa-id-card-alt',
          title: 'shared.student-tracking',
          showInNav: true,
          priority: 5,
        },
      },

      {
        path: 'sav',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./sav/sav.module')).SavModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['sav_*'] },
          icon: 'mtx-icon mtx-icon-stop-sign',
          title: 'sav.sav',
          showInNav: true,
          priority: 3,
        },
      },

      {
        path: 'video',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./video/video.module')).VideoModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['vm_*'] },
          icon: 'fas fa-download',
          title: 'general.video',
          showInNav: true,
          priority: 6,
        },
      },

      {
        path: 'health',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./health/health.module')).HealthModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['health_*'] },
          icon: 'fas fa-file-waveform',
          title: 'health.health',
          showInNav: true,
          priority: 9,
        },
      },

      {
        path: 'live-view',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./live-view/live-view.module')).LiveViewModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['lv_*'] },
          icon: 'fas fa-video',
          title: 'general.live-view',
          showInNav: true,
          priority: 9,
        },
      },

      {
        path: 'super',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./super/super.module')).SuperModule,
        canLoad: [AuiUserCanLoadGuard],
        data: {
          permissions: { oneOf: ['super'] },
          icon: 'fas fa-hat-wizard',
          title: 'general.super',
          showInNav: true,
        },
      },

      {
        path: 'error-pages',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./error-pages/error-pages.module')).ErrorPagesModule,
        data: { showInNav: false },
      },

      {
        path: 'driver-incident',
        pathMatch: 'prefix',
        loadChildren: async() => (await import('./driver-incident/driver-incident.module')).DriverIncidentModule,
        data: { showInNav: false },
      },

      {
        path: '**',
        redirectTo: '/',
        data: { showInNav: false },
      },

    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
