/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { LoggerService, ParamSerializer } from './shared';
import type { ResolveFn } from '@angular/router';
import { AuthService } from './auth.service';
import type { AuiRoute, AuiRoutes } from '@angeltrax/ngx-aui/lib/shared';

// finds the authorized route with the highest priority.
const findHighestPrioRoute = (routes: AuiRoutes, perms: string[]): AuiRoute | null => {
  const sortedRoutes = routes?.filter(x => (x.data?.priority ?? 0) > 0)
    .sort((a: AuiRoute, b: AuiRoute) => (a.data!.priority ?? 100) - (b.data!.priority ?? 100));
  for (const route of sortedRoutes) {
    if (!route.data?.permissions) {
      return route;
    }

    // handles avl_* type permissions
    const anyPerm = (route.data?.permissions?.oneOf ?? []).find(x => x.includes('_*')) ??
    (route.data?.permissions?.allOf ?? []).find(x => x.includes('_*'));
    if (anyPerm) {
      const prefix = anyPerm.substring(0, anyPerm.indexOf('*'));

      if (perms.some(x => x.startsWith(prefix))) {
        return route;
      }
    }

    // checks user permissions against permissions defined on route
    const hasRoutePermission = perms.some(x => route.data?.permissions?.oneOf?.includes(x)) ??
    perms.some(x => route.data?.permissions?.allOf?.includes(x)) ?? '';
    if (hasRoutePermission) {
      return route;
    }
  }

  return null;
};


export const ModuleRedirectResolver: ResolveFn<void> =
 (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void => {
   const router = inject(Router);
   const paramSerializer = inject(ParamSerializer);
   const logger = inject(LoggerService);
   const authService = inject(AuthService);
   const filteredRoutes: AuiRoutes = (route.parent?.routeConfig?.children as AuiRoutes | undefined) ?? [];

   authService.getCurrentOrganization().pipe(first())
     .subscribe({
       next: (res) => {
         let prioRoute = findHighestPrioRoute(filteredRoutes, res.permissions);
         let highestPriorityAuthorizedRoutePath = prioRoute?.path ?? '';
         while (prioRoute?.children && prioRoute.children.length > 0) {
           prioRoute = findHighestPrioRoute(prioRoute.children, res.permissions);
           highestPriorityAuthorizedRoutePath = highestPriorityAuthorizedRoutePath.concat(`/${prioRoute?.path ?? ''}`);
         }

         if (prioRoute?.data?.queryParams) {
           const params = paramSerializer.serialize(prioRoute.data?.queryParams as Record<string, any>);
           highestPriorityAuthorizedRoutePath = highestPriorityAuthorizedRoutePath.concat(`?${params}`);
         }


         if (highestPriorityAuthorizedRoutePath) {
           // navigate to path of highest priority authorized
           router.navigateByUrl(`${state.url}/${highestPriorityAuthorizedRoutePath}`)
             .catch((err: Error) => {
               logger.logError(err);
             });
         } else if (state.url === '/') {
           // if no module path found, direct to no modules error page
           router.navigateByUrl('/error-pages/no-modules')
             .catch((err: Error) => {
               logger.logError(err);
             });
         } else {
           // if no submodules path found, direct back to top level
           router.navigateByUrl('/')
             .catch((err: Error) => {
               logger.logError(err);
             });
         }
       },
       error: (err) => {
         if (err.status === 403) {
           if (!router.url.includes('/users/join/')) {
             // error response for if user is not part of an org
             router.navigateByUrl('/error-pages/access-denied')
               .catch((err2: Error) => {
                 logger.logError(err2);
               });
           }
         }
       },
     });
 };

