import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OrganizationProfileService } from '../admin/organization-profiles/shared/organization-profile.service';
import { SharedModule } from '../shared/shared.module';
import { ChangeOrgModalComponent } from './change-org-modal/change-org-modal.component';
import { UserOrganizationService } from './change-org-modal/shared/user-organization.service';
import { UserNotificationService } from './notification-edit-modal/shared/user-notification.service';
import { UserJoinOrgComponent } from './user-join-org/user-join-org.component';
import { UserPreferencesService } from './user-preferences/shared/user-preferences.service';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { UserRoutingModule } from './user-routing.module';

/**
 * Module for directing user profile and join org
 */
@NgModule({ declarations: [
  UserJoinOrgComponent,
  UserPreferencesComponent,
  ChangeOrgModalComponent,
],
exports: [],
imports: [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  SharedModule,
  UserRoutingModule,
  TranslateModule.forChild({
    extend: true,
  }),
],
providers: [
  UserPreferencesService,
  UserNotificationService,
  UserOrganizationService,
  OrganizationProfileService,
  provideHttpClient(withInterceptorsFromDi()),
] })
export class UserModule {

  /**
   * Throws error if Usermodule has already been loaded
   */
  public constructor(@Optional() @SkipSelf() parentModule: UserModule) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (parentModule) {
      throw new Error('UserModule is already loaded. Import it in the AppModule only');
    }
  }

}
