import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {
  HttpErrorResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

import type { Observable } from 'rxjs/internal/Observable';
import { catchError, switchMap } from 'rxjs/operators';
import { interceptorSkipHeader } from 'src/app/auth.service';
import { environment } from 'src/environments/environment';
import { UrlFixerService } from '..';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public constructor(@Inject(Router) private readonly router: Router,
    @Inject(UrlFixerService) private readonly urlFixerService: UrlFixerService) { }

  public intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    const skipHandlingErrors = Boolean(req.headers.has(interceptorSkipHeader));

    if (!req.url.startsWith(environment.apiBaseUrl)) {
      return next
        .handle(req)
        .pipe(catchError((err: Error) => this.errHandler(err, skipHandlingErrors)));
    }

    if (!this.urlFixerService.needsOrgAddedToUrl(req.url)) {
      return next
        .handle(req)
        .pipe(catchError((err: Error) => this.errHandler(err, skipHandlingErrors)));
    }

    return this.urlFixerService.fixUrl(req.url).pipe(switchMap((url: string) => {
      const httpRequest = req.clone({ url });
      // req = Object.assign(req, httpRequest);

      return next
        .handle(httpRequest)
        .pipe(catchError((err: Error) => this.errHandler(err, skipHandlingErrors)));
    }));
  }

  private redirectIfNotPage(url: string): void {
    if (!window.location.pathname.startsWith(url)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.router.navigateByUrl(url);
    }
  }

  private errHandler(err: Error, skipHandlingErrors: boolean): Observable<never> {
    // eslint-disable-next-line no-console
    console.log('intecept err', err);
    if (skipHandlingErrors) {
      return throwError(() => err);
    }
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401) {
        // eslint-disable-next-line no-console
        console.log('intercept 401 redirect', err);
        this.redirectIfNotPage('/login');
      } else if (err.status === 403) {
        if (err.error) {
          this.redirectIfNotPage(`/error-pages/access-denied?reason=${err.error[0]}`);
        } else {
          this.redirectIfNotPage('/error-pages/access-denied');
        }
      }
    }
    return throwError(() => err);
  }

}
