import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { Injector } from '@angular/core';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import type { Params, Route } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import type { Observable } from 'rxjs';
import { from, map, share } from 'rxjs';
import { of } from 'rxjs';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth.service';
import type { UserOrg } from 'src/app/auth.service';
import { ChangeOrgModalComponent } from 'src/app/user/change-org-modal/change-org-modal.component';
import { NotificationEditModalComponent } from 'src/app/user/notification-edit-modal/notification-edit-modal.component';
import type { INavItemNeedsAttentionService } from './nav-item-needs-attention.service';
import { FilterLinksByPermissionPipe } from '../../pipes/filter-links-by-permission.pipe';
import { PermissionList } from '../../classes/permission-list';
import type { AuiRoute, AuiRoutes } from '@angeltrax/ngx-aui/lib/shared';

/** Component used to display header */
@Component({
  selector: 'mtx-header-full',
  templateUrl: './header-full.component.html',
  styleUrls: ['./header-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderFullComponent implements OnInit, OnChanges {

  public links$: Observable<AuiRoutes>;

  public moduleLinks$: Observable<{ accessableLinks: AuiRoutes; disabledLinks: AuiRoutes }>;

  public moduleName?: string;

  /** current org name to display on header */
  public currentOrgName?: string;

  /** (Probably tempory) Array of Objects populating Module switcher */

  /** Boolean used to check if moduleSwitcher is open */
  public moduleSwitcherOpen = false;

  /** Boolean used to check if mobile header should be displayed */
  public showResponsiveMenu = false;

  /** Boolean used to check if child links of an Ilinks object should be displayed */
  public showingChildLinks = false;

  /** Observable<boolean> used to check if user is logged in */
  public isLoggedIn$?: Observable<boolean>;

  /** If menu is collapsed */
  public isCollapsed = true;

  /** If user is super */
  public super = false;

  public currentQueryParams: Params = {};

  /** Array of permissions */
  public permissions: string[] = [];

  public issuedByAcctsAT?: boolean;

  public queryParams?: Params;

  public permissionList = PermissionList;

  /** Observable for loading user and organization */
  private loadingRequest?: Observable<[UserOrg]>;

  private needsAttentionCache: Record<string, Observable<boolean>> = {};

  /**
   * Sets date and calls activate
   */
  public constructor(@Inject(NgbModal) private readonly modalService: NgbModal,
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(Router) private readonly router: Router,
    @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
    @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
    @Inject(FilterLinksByPermissionPipe) private readonly filterLinksByPermissionPipe: FilterLinksByPermissionPipe,
    @Inject(Injector) private readonly injector: Injector) {
    this.moduleLinks$ = filterLinksByPermissionPipe
      .transform(((this.router.config as AuiRoutes).find(x => x.path === '')
        ?.children ?? [])?.filter(x => x.data?.showInNav));
    this.moduleName = this.route.pathFromRoot[2]?.snapshot?.routeConfig?.path;
    this.links$ = filterLinksByPermissionPipe
      .transform((this.route.routeConfig as AuiRoute)?.children?.filter(x => x.data?.showInNav) ?? [])
      .pipe(map(x => x.accessableLinks));
    this.activate();
  }

  public ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.cdr.detectChanges();
        this.needsAttentionCache = {};
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.links) {
      this.needsAttentionCache = {};
    }
  }

  /*
   * ngAfterViewChecked() {
   *   this.setDropdownHighlights();
   * }
   */

  /**
   * Pops up change org modal
   */
  public openChangeOrgModal(): void {
    this.modalService.open(ChangeOrgModalComponent);
  }

  /**
   * Pops up user notifs modal
   */
  public openUserNotifsModal(): void {
    this.modalService.open(NotificationEditModalComponent);
  }

  /**
   * Logs out of oauth service
   */
  public async logout(): Promise<void> {
    await this.authService.clearCacheAndLogout();
  }

  public showAttentionIcon(route: Route): Observable<boolean> {
    if (!route.data?.attentionService || !route.path) {
      return of(false);
    }

    if (Object.prototype.hasOwnProperty.call(this.needsAttentionCache, route.path)) {
      return this.needsAttentionCache[route.path];
    }


    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const svc = this.injector.get<INavItemNeedsAttentionService>(route.data.attentionService);

    if (svc === undefined) {
      return of(false);
    }

    this.needsAttentionCache[route.path] = svc.checkNeedsAttention().pipe(share());

    return this.needsAttentionCache[route.path];
  }

  /**
   * toggles the navbar dropdowns on hover instead of click
   * @param event mouse event
   * @param ddID dropdown id
   */
  public toggleDropdown(event: MouseEvent, ddID: string): void {
    setTimeout(() => {
      const dd: HTMLElement | null = document.getElementById(ddID);

      if (!dd) {
        return;
      }

      if (event.type === 'mouseover') {
        dd.children[1].classList.add('show');
        dd.children[0]?.querySelector('.fa-angle-right')?.classList.replace('fa-angle-right',
          'fa-angle-down');
      } else if (event.type === 'mouseleave') {
        dd.children[1].classList.remove('show');
        dd.children[0]?.querySelector('.fa-angle-down')?.classList.replace('fa-angle-down',
          'fa-angle-right');
      }
    },
    event.type === 'mouseleave' ? 200 : 0);
  }

  public anyChildIsActive(route: Route): boolean {
    if (route.children && route.path) {
      return (
        this.router.url.includes(route.path) &&
        Boolean(route.children.find(x => x.path && this.router.url.includes(x.path)))
      );
    }
    return false;
  }

  public hasNavChildLinks(link: Route): boolean {
    return Boolean(link.children?.find(x => x.data?.showInBehaviorNav));
  }

  public applyQueryParams(link: Route): Params {
    const qp: Params = (link.data?.queryParams as Record<string, any>) ?? {};

    if (link.data?.withCurrentQueryParams?.length > 0) {
      link.data?.withCurrentQueryParams.forEach((qpKey: string) => {
        if (this.route.snapshot.queryParams[qpKey]) {
          qp[qpKey] = this.route.snapshot.queryParams[qpKey];
        }
      });
    }
    return qp;
  }

  /**
   * Makes permission request for links
   */
  private activate(): void {
    this.loadingRequest = forkJoin([this.authService.getCurrentOrganization()]);
    this.isLoggedIn$ = from(this.authService.isLoggedIn());
    this.loadingRequest.subscribe({
      next: (res) => {
        const checkOrg = res[0];

        this.issuedByAcctsAT =
          this.authService.getUserInfo().iss ===
          'https://accounts.angeltrax.com/realms/master';
        this.currentOrgName = checkOrg.name;
        this.permissions = checkOrg.permissions;
        this.loadingRequest = undefined;
      },
    });
  }

}
