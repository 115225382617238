import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './login/login.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpLoaderFactory } from './http-loader';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { environment } from '../environments/environment';

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ReactiveFormsModule } from '@angular/forms';
import { MapModule } from './avl/maps';
import { UrlFixerService } from './shared';
import { AuthInterceptor } from './shared/api-helpers/auth-interceptor';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
import { AUI_AUTH_SVC_TOKEN, IdleService, provideAuth } from '@angeltrax/ngx-aui';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from 'ngx-webstorage';
import { AuthService } from './auth.service';

/**
 * OAuth storage
 * @param err RevenError
 */
export const oauthStorageServiceFactory = (): Storage => {
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  if (window.navigator.userAgent.match(/MSIE|Trident/) !== null) {
    window.addEventListener('storage', () => {
      // do nothing
    });
  }

  return localStorage;
};

@NgModule({ declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiBaseUrl],
        sendAccessToken: true,
      },
    }),
    NgbModule,
    // eager load modules
    MapModule.forRoot(),
    UserModule,
    SharedModule,
    LeafletModule,
    LoginModule,
    LeafletModule,
    // lazy load modules
    AppRoutingModule,
  ],
  providers: [
    { provide: AUI_AUTH_SVC_TOKEN, useExisting: AuthService },
    provideAuth(environment.oauthConfig,
      { resourceServer: { sendAccessToken: true, allowedUrls: [environment.apiBaseUrl] } }),
    {
      provide: OAuthStorage,
      useFactory: oauthStorageServiceFactory,
      deps: [],
    },
    UrlFixerService,
    provideNgxWebstorage(
      withNgxWebstorageConfig({ separator: ':', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage(),
    ),
    IdleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ] })
export class AppModule {}
