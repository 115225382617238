/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import type { AuiRoute } from '@angeltrax/ngx-aui/lib/shared';
import type { PipeTransform } from '@angular/core';
import { Inject } from '@angular/core';
import { Pipe } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import { first, forkJoin, from, of } from 'rxjs';
import { AuthService } from 'src/app/auth.service';

@Pipe({ name: 'filterLinksByPermission' })
export class FilterLinksByPermissionPipe implements PipeTransform {

  public constructor(@Inject(AuthService) private readonly authService: AuthService) {}

  public transform(links: AuiRoute[]): Observable<{ accessableLinks: AuiRoute[]; disabledLinks: AuiRoute[] }> {
    const obsStreams: Observable<{ hasPermission: boolean; link: AuiRoute; childLink?: AuiRoute }>[] = [];
    links.forEach((link) => {
      const permissions = link.data?.permissions;
      if (((permissions?.oneOf ?? []).length === 0 && (permissions?.allOf ?? []).length === 0) && !link.children) { // NO PERMISSIONS
        // push link if no permissions and no children
        obsStreams.push(of({
          hasPermission: true,
          link,
        }));
      } else if (link.children) { // PERMISSIONS WITH CHILDREN (DROPDOWNS)
        // if the link contains children, check permissions of childlinks and push appropriately. push link if at least one childlink
        link.children.forEach((childLink) => {
          const childPermissions = childLink.data?.permissions;
          if ((childPermissions?.allOf ?? []).length > 0) {
            obsStreams.push(from(this.authService.checkUserHasAllPermissions(childPermissions?.allOf ?? [])).pipe(map(x => ({
              hasPermission: x,
              link,
              childLink,
            }))));
          } else if ((childPermissions?.oneOf ?? []).length > 0) {
            obsStreams.push(from(this.authService.checkUserHasAtLeastOnePermission(childPermissions?.oneOf ?? [])).pipe(map(x => ({
              hasPermission: x,
              link,
              childLink,
            }))));
          }
        });
      } else if ((permissions?.allOf ?? []).length > 0 || (permissions?.oneOf ?? []).length > 0) { // PERMISSIONS NO CHILDREN
        // if permissions exist and no children, check permissions and push link
        if ((permissions?.allOf ?? []).length > 0) {
          obsStreams.push(from(this.authService.checkUserHasAllPermissions(permissions?.allOf ?? [])).pipe(map(x => ({
            hasPermission: x,
            link,
          }))));
        } else if ((permissions?.oneOf ?? []).length > 0) {
          obsStreams.push(from(this.authService.checkUserHasAtLeastOnePermission(permissions?.oneOf ?? [])).pipe(map(x => ({
            hasPermission: x,
            link,
          }))));
        }
      }
    });

    return forkJoin(obsStreams).pipe(first(), map((x) => {
      const disabledLinks = x.filter(y => !y.hasPermission).map(y => y.link);
      const accessableLinks = x.filter(y => y.hasPermission)
        .map((y) => {
          y.link.children = y.childLink ? [] : undefined;
          return y;
        })
        .reduce((accumulator: AuiRoute[], current: {
          link: AuiRoute;
          childLink?: AuiRoute;
        }) => {
        // if a link with children already exists in the accumulator, push remaining childlinks
          const parentLink = accumulator.find(route => route === current.link);

          if (parentLink) {
            parentLink.children!.push(current.childLink!);
          } else if (current.childLink) {
            current.link.children!.push(current.childLink);
            accumulator.push(current.link);
          } else {
            accumulator.push(current.link);
          }
          return accumulator;
        }, []);

      return {
        accessableLinks,
        disabledLinks,
      };
    }));
  }

}

