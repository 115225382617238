import { Component, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth.service';
import { ChangeOrgModalComponent } from 'src/app/user/change-org-modal/change-org-modal.component';
import pkgInfo from '../../../../../package.json';

/**
 * Component to display mtx header view
 */
@Component({
  selector: 'mtx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  public isLoggedIn = false;

  public appVersion: string = pkgInfo.version;

  // ui exposed
  /** Current date */
  public date: Date;

  /**
   * Sets date
   */
  public constructor(@Inject(AuthService) private readonly authService: AuthService,
    @Inject(NgbModal) private readonly modalService: NgbModal) {
    this.date = new Date();
    this.authService.isLoggedIn()
      .then((x) => {
        if (x) {
          this.isLoggedIn = true;
        }
      })
      .catch(() => {
        // todo: why do nothing
      });
  }

  public async logout(): Promise<void> {
    await this.authService.clearCacheAndLogout();
  }

  /**
   * Pops up change org modal
   */
  public openChangeOrgModal(): void {
    this.modalService.open(ChangeOrgModalComponent);
  }

}
