// eslint wants to import AuiAuthConfig as type, but we need a full import for tests to pass
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AUI_AUTH_CONFIG_TOKEN, AuiAuthConfig, AuiAuthService } from '@angeltrax/ngx-aui';
import { Inject, Injectable } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import type { Observable } from 'rxjs';
import { catchError } from 'rxjs';
import { firstValueFrom, forkJoin, map, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import type { UserProfileDescription } from './user/user-profile/shared/user-profile';

interface UserPrefs {
  default_organization: {
    id: string;
    name: string;
    time_zone: string;
  };
  default_timezone: string;
  locale: string;
  date_format: string;
  use_24_hour_clock: boolean;
}

interface User {
  id: string;
  organization_identifier: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  is_registered_user?: boolean;
  email: string;
  middle_name: string;
  suffix?: string;
  prefix?: string;
  dob: string;
  gender: string;
  role: {
    id: string;
    name?: string;
  };
}

export const interceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuiAuthService<UserOrg> {

  private readonly useMemoryStore = false;

  private memoryStorage: Record<string, string> = {};

  private readonly STOREKEYS = {
    USER_PREFERENCES: 'user_preferences_v4',
    USER_PROFILE: 'user_profile_v4',
    CURRENT_ORGANIZATION: 'current_organization_v4',
    ALLOWED_ORGANIZATIONS: 'allowed_organizations_v4',
  };

  public constructor(
    @Inject(HttpClient) private readonly http: HttpClient,
    @Inject(OAuthService) private readonly oauth: OAuthService,
    @Inject(Router) protected override readonly router: Router,
    @Inject(AUI_AUTH_CONFIG_TOKEN) config: AuiAuthConfig,
    @Inject(LocalStorageService) private readonly storageService: LocalStorageService,
  ) {
    super(oauth, config, router);
  }

  public async clearCacheAndLogout(): Promise<void> {
    this.clearCache();
    await this.logout();
  }

  public override async fetchUserPermissions(user: UserOrg): Promise<string[]> {
    return Promise.resolve(user.permissions);
  }

  public override async fetchCurrentUser(): Promise<UserOrg | null> {
    const cachedOrg = this.getStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION);

    if (cachedOrg) {
      return firstValueFrom(of(JSON.parse(cachedOrg)));
    }

    const userOrg = await firstValueFrom(forkJoin([
      this.getCurrentUserPrefs(),
      this.getAllowedOrganizations(),
    ]).pipe(map((res: [UserPrefs, UserOrg[]]) => res[1].find(x => x.id === res[0].default_organization.id)!),
      tap((dfltOrg) => {
        const str = JSON.stringify(dfltOrg);
        this.setStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION, str);
      }),
      catchError(async(err: HttpErrorResponse) => {
        if (err.status !== 401 && err.status !== 403) {
          await this.router.navigate(['/error-pages/error']);
        }
        return null;
      })));
    return userOrg;
  }

  public updateLocalUserData(): Observable<unknown> {
    const url = `${environment.apiBaseUrl}/user/profile`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
      // .set(interceptorSkipHeader, '');
    const req = this.http.put<User>(url, null, { headers }).pipe(map(() => {
      // idr why we had void return type
    }));
    return req;
  }

  public getAllowedOrganizations(): Observable<UserOrg[]> {
    const cachedPrefs = this.getStoredValue(this.STOREKEYS.ALLOWED_ORGANIZATIONS);

    if (cachedPrefs) {
      return of(JSON.parse(cachedPrefs));
    }

    const url = `${environment.apiBaseUrl}/user/organizations`;

    return this.http
      .get<UserOrg[]>(url)
      .pipe(tap((prefs) => {
        this.setStoredValue(this.STOREKEYS.ALLOWED_ORGANIZATIONS, JSON.stringify(prefs));
      }));
  }

  public swapOrg(org: UserOrg): void {
    this.clearCache();
    const str = JSON.stringify(org);
    this.setStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION, str);
  }

  public getCurrentUserPrefs(): Observable<UserPrefs> {
    const cachedPrefs = this.getStoredValue(this.STOREKEYS.USER_PREFERENCES);

    if (cachedPrefs) {
      return of(JSON.parse(cachedPrefs));
    }

    const url = `${environment.apiBaseUrl}/user/preferences`;

    // const headers = new HttpHeaders().append(interceptorSkipHeader, '');

    return this.http
      .get<UserPrefs>(url, { /* headers */ })
      .pipe(tap((prefs) => {
        this.setStoredValue(this.STOREKEYS.USER_PREFERENCES, JSON.stringify(prefs));
      }));
  }

  public getCurrentOrganization(): Observable<UserOrg> {
    const cachedOrg = this.getStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION);

    if (cachedOrg) {
      return of(JSON.parse(cachedOrg));
    }

    return forkJoin([
      this.getCurrentUserPrefs(),
      this.getAllowedOrganizations(),
    ]).pipe(map((res: [UserPrefs, UserOrg[]]) => res[1].find(x => x.id === res[0].default_organization.id)!),
      tap((dfltOrg) => {
        const str = JSON.stringify(dfltOrg);
        this.setStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION, str);
      }));
  }

  public getUserProfile(): Observable<UserProfileDescription> {
    const cachedPrefs = this.getStoredValue(this.STOREKEYS.USER_PROFILE);

    if (cachedPrefs) {
      return of(JSON.parse(cachedPrefs));
    }
    const url = `${environment.apiBaseUrl}/user/profile`;
    return this.http
      .get<UserProfileDescription>(url)
      .pipe(tap((prefs) => {
        this.setStoredValue(this.STOREKEYS.USER_PROFILE, JSON.stringify(prefs));
      }));
  }

  public getUserInfo(): {
    first_name: string;
    last_name: string;
    id: string;
    email: string;
    phone: string;
    email_verified: boolean;
    phone_verified: boolean;
    iss: string;
  } {
    const claims: any = this.oauth.getIdentityClaims();

    return {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      first_name: claims.given_name,
      last_name: claims.family_name,
      id: claims.sub,
      email: claims.email,
      phone: claims.phone_number,
      email_verified: claims.email_verified,
      phone_verified: claims.phone_number_verified,
      iss: claims.iss,
    };
  }

  public getOauthAccessToken(): string {
    return this.oauth.getAccessToken();
  }

  public clearCache(): void {
    this.storageService.clear('current_organization');
    this.storageService.clear('user_preferences');
    this.storageService.clear('allowed_organizations');
    this.clearStoredValue(this.STOREKEYS.CURRENT_ORGANIZATION);
    this.clearStoredValue(this.STOREKEYS.USER_PREFERENCES);
    this.clearStoredValue(this.STOREKEYS.ALLOWED_ORGANIZATIONS);
  }

  private setStoredValue(key: string, value: any): void {
    if (this.useMemoryStore) {
      this.memoryStorage[key] = value;
    } else {
      this.storageService.store(key, value);
    }
  }

  private getStoredValue(key: string): string {
    if (this.useMemoryStore) {
      return this.memoryStorage[key];
    }
    return this.storageService.retrieve(key);
  }

  private clearStoredValue(key: string): void {
    if (this.useMemoryStore) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.memoryStorage[key];
    } else {
      this.storageService.clear(key);
    }
  }

}


export interface UserOrg {
  id: string;
  name: string;
  time_zone: string;
  modules: {
    driver_behavior_enabled: boolean;
    avl_enabled: boolean;
    transit_enabled: boolean;
    sav_enabled: boolean;
    video_enabled: boolean;
    health_enabled: boolean;
    student_tracking_enabled: boolean;
    retention_period_months: number;
    live_view_enabled: boolean;
  };
  permissions: string[];
}
