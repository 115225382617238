import { environment } from '../environments/environment';
import type { Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import type { Event } from '@angular/router';
import { NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from './user/user-preferences/shared/user-preferences.service';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IdleService } from '@angeltrax/ngx-aui';
import { AuthService } from './auth.service';

@Component({
  selector: 'mtx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  public isLoadingRoute = false;

  public isLoadingApp = true;

  public authSetupFailed = false;

  public authWindowReload?: Observable<number>;

  private readonly title = 'mototrax-webui';

  private readonly isProduction = environment.production;

  private logoutSubscription?: Subscription;

  private readonly loginSubscription?: Subscription;

  private authSub?: Subscription;

  /**
   * Sets google analytics and navigatorIntercepter
   */
  public constructor(@Inject(Router) private readonly router: Router,
    @Inject(TranslateService) private readonly translate: TranslateService,
    @Inject(IdleService) private readonly idleService: IdleService,
    @Inject(UserPreferencesService) private readonly userPreferencesService: UserPreferencesService,
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(DestroyRef) private readonly destroy: DestroyRef) {
    router.events.subscribe((event) => {
      this.interceptNavigation(event);
    });
  }

  /** Translates page */
  public ngOnInit(): void {
    this.configureTranslations();

    this.idleService.idle$.pipe(takeUntilDestroyed(this.destroy))
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe(async(isIdle) => {
        if (isIdle) {
          if (await this.authService.isLoggedIn()) {
            const path = this.router.url;
            await this.authService.logout(path);
          }
        }
      });

    this.router.events.pipe(delay(0)).subscribe((evt) => {
      this.interceptNavigation(evt);
    });
  }

  /**
   * Destroys subs on exit
   */
  public ngOnDestroy(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
      this.logoutSubscription = undefined;
    }

    if (this.authSub) {
      this.authSub.unsubscribe();
      this.authSub = undefined;
    }
  }

  private configureTranslations(): void {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  /**
   * Makes sure navigation can't be interrupted
   * @param event Event
   */
  private interceptNavigation(event: Event): void {
    if (event instanceof NavigationStart) {
      this.isLoadingRoute = true;
    } else if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel
    ) {
      this.isLoadingRoute = false;
    } else if (event instanceof NavigationError) {
      if (environment.production) {
        window.location.href = window.location.origin + event.url;
      } else {
        this.isLoadingRoute = false;
      }
    }
  }

}
