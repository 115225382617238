<div class="d-flex datetimepicker" ngbDropdown>
    <button type="button" class="datepicker btn btn-link flex-grow-1"
        ngbDropdownToggle>
        <mtx-datetime *ngIf="tgtFormControlNameTime && dispValue && dispValue !== ''" style="display: inline-flex;" [inputDate]="dispValue"></mtx-datetime>
        <mtx-date *ngIf="!tgtFormControlNameTime && dispValue && dispValue !== ''" style="display: inline-flex;" [inputDate]="dispValue"></mtx-date>
        <span *ngIf="!dispValue || dispValue === ''">Select a date and time</span>
    </button>
    <div id="ddMenu" ngbDropdownMenu>
        <ngb-datepicker #dp [(ngModel)]="dateTimeFilters.date" [firstDayOfWeek]="7"
            [ngModelOptions]="{standalone:true}" (ngModelChange)="updateDateTime()"
            #minDateTime="ngbDatepicker"></ngb-datepicker>
        <ngb-timepicker *ngIf="tgtFormControlNameTime" [(ngModel)]="dateTimeFilters.time" [meridian]="useMeridian"
            (ngModelChange)="updateDateTime()" [seconds]="true"
            [ngModelOptions]="{standalone:true}"></ngb-timepicker>
            <button id="clearBtn" class="btn" type="button" (click)="clearDate()" translate="general.clear"></button>
    </div>
</div>
